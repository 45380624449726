<template>

  <div class="d-flex justify-content-end m-2">
    <button class="btn btn-light fs-17 rounded" @click="toggleFullScreen"><i class="bx bx-fullscreen"/> </button>

  </div>
  <div style="height: 100vh;">

    <section  ref="powerBISection" class="h-100" v-bind="$attrs"></section>
  </div>

</template>


<script>
import { service, factories, models } from "powerbi-client";
import { onMounted, ref, watch, computed } from "vue";
import Swal from "sweetalert2";
import { useStore } from "vuex";

export default {
  props: {
    powerBIData: {
      type: Object,
    },
    filterPBI: {
      type: Array,
    },
  },
  inheritAttrs: false,
  setup(props, { attrs }) {
    const store = useStore();
    const refreshBI = computed(() => store.state.painelPrograma.refreshPBI);
    const dashboardInstance = ref(null);
    const lastRefreshTime = ref(0);
    const isFullScreen = ref(false);
    const filters = [];
    const powerBISection = ref(null);

    function refreshDashboard() {
      const now = Date.now();
      const timeSinceLastRefresh = now - lastRefreshTime.value;

      if (timeSinceLastRefresh < 15000) {
        Swal.fire({
          icon: "warning",
          title: "Aguarde",
          text: `Por favor, aguarde ${15 - Math.round(timeSinceLastRefresh / 1000)} segundos antes de tentar novamente.`,
        });
        return;
      }

      lastRefreshTime.value = now;

      if (dashboardInstance.value) {
        dashboardInstance.value.refresh();
      } else {
        console.error("Dashboard instance not available for refresh");
        Swal.fire({
          icon: "error",
          title: "Refresh Error",
          text: "Dashboard instance is not available for refresh.",
        });
      }
    }

    async function callPBI() {
      try {
        const dashboardContainer = document.getElementById(attrs.id);

        if (!dashboardContainer) {
          console.error("Dashboard container not found");
          Swal.fire({
            icon: "error",
            title: "Container Not Found",
            text: "Dashboard não encontrado.",
          });
          return;
        }

        let powerbi = new service.Service(
          factories.hpmFactory,
          factories.wpmpFactory,
          factories.routerFactory
        );

        props.filterPBI?.forEach((val) => {
          let filter = {
            $schema: "http://powerbi.com/product/schema#basic",
            target: { table: val.tabela_filtro, column: val.coluna_filtro },
            filterType: models.FilterType.Basic,
            operator: "In",
            values: val.parametro_filtro,
            displaySettings: {
              isLockedInViewMode: true,
              isHiddenInViewMode: true,
            },
          };
          filters.push(filter);
        });

        const conf = {
          type: "report",
          tokenType: models.TokenType.Aad,
          accessToken: props.powerBIData?.access_token,
          embedUrl: props.powerBIData?.url_embedded,
          id: props.powerBIData?.id_painel,
          filters: filters,
          settings: {
            panes: {
              filters: {
                expanded: true,
                visible: true,
              },
            },
          },
        };

        dashboardInstance.value = powerbi.embed(dashboardContainer, conf);

        // Event listeners for embed events
        dashboardInstance.value.off("loaded", (ev) => {
          console.log("loaded", ev);
        });
        dashboardInstance.value.off("rendered", (ev) => {
          console.log("rendered", ev);
        });

        // Error handling during the embed process
        dashboardInstance.value.on("error", function (event) {
          console.error("PowerBI Embed Error:", event.detail);
          const errorCode = event.detail?.statusCode || "Unknown";

          if (errorCode === 403) {
            Swal.fire({
              icon: "error",
              title: "Access Denied",
              text: "Sua sessão foi expirada, atualize a página.",
            });
          } else if (errorCode === 401) {
            Swal.fire({
              icon: "error",
              title: "Unauthorized",
              text: "Seu token foi expirado, atualize a página.",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Embedding Error",
              text: "Um erro ocorreu ao montar a página, tente novamente.",
            });
          }
        });
      } catch (error) {
        console.error("Error in callPBI function:", error);
        Swal.fire({
          icon: "error",
          title: "Embedding Error",
          text: "Um erro inesperado ocorreu, atualize a página!",
        });
      }
    }

    function toggleFullScreen() {
      const elem = powerBISection.value;

      if (!isFullScreen.value) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    }

    watch(
      () => refreshBI.value,
      () => refreshDashboard()
    );

    onMounted(() => {
      callPBI();

      // Exit fullscreen on ESC key
      document.addEventListener("keydown", (e) => {
        if (e.key === "Escape" && isFullScreen.value) {
          toggleFullScreen();
        }
      });
    });

    return {
      refreshDashboard,
      refreshBI,
      toggleFullScreen,
      isFullScreen,
      powerBISection,
    };
  },
};
</script>


